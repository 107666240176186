import * as i0 from '@angular/core';
import { inject, Directive, Input, EventEmitter, ElementRef, NgZone, afterNextRender, Output } from '@angular/core';
import { DOCUMENT } from '@angular/common';

/**
 * Optimization for Treeshaking: https://angular.io/guide/lightweight-injection-tokens
 */
class NgClickOutsideExcludeToken {}
class NgClickOutsideExcludeDirective extends NgClickOutsideExcludeToken {
  constructor() {
    super(...arguments);
    /**
     * A comma-separated string of DOM element queries to exclude when clicking outside of the element.
     * For example: `[exclude]="'button,.btn-primary'"`.
     */
    this.clickOutsideExclude = '';
    this.document = inject(DOCUMENT);
  }
  excludeCheck() {
    if (this.clickOutsideExclude) {
      try {
        const nodes = Array.from(this.document.querySelectorAll(this.clickOutsideExclude));
        if (nodes) {
          return nodes;
        }
      } catch (err) {
        console.error('[ng-click-outside] Check your exclude selector syntax.', err);
      }
    }
    return [];
  }
  isExclude(target) {
    const nodesExcluded = this.excludeCheck();
    for (let excludedNode of nodesExcluded) {
      if (excludedNode.contains(target)) {
        return true;
      }
    }
    return false;
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵNgClickOutsideExcludeDirective_BaseFactory;
      return function NgClickOutsideExcludeDirective_Factory(__ngFactoryType__) {
        return (ɵNgClickOutsideExcludeDirective_BaseFactory || (ɵNgClickOutsideExcludeDirective_BaseFactory = i0.ɵɵgetInheritedFactory(NgClickOutsideExcludeDirective)))(__ngFactoryType__ || NgClickOutsideExcludeDirective);
      };
    })();
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: NgClickOutsideExcludeDirective,
      selectors: [["", "clickOutsideExclude", ""]],
      inputs: {
        clickOutsideExclude: "clickOutsideExclude"
      },
      standalone: true,
      features: [i0.ɵɵProvidersFeature([{
        provide: NgClickOutsideExcludeToken,
        useExisting: NgClickOutsideExcludeDirective
      }]), i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgClickOutsideExcludeDirective, [{
    type: Directive,
    args: [{
      selector: '[clickOutsideExclude]',
      standalone: true,
      providers: [{
        provide: NgClickOutsideExcludeToken,
        useExisting: NgClickOutsideExcludeDirective
      }]
    }]
  }], null, {
    clickOutsideExclude: [{
      type: Input
    }]
  });
})();

/**
 * Directove to detect clicks outside of the current element
 *
 * ```typescript
 * @Component({
 *   selector: 'app',
 *   template: `
 *     <div (clickOutside)="onClickedOutside($event)">Click outside this</div>
 *   `
 * })
 * export class AppComponent {
 *   onClickedOutside(e: Event) {
 *     console.log('Clicked outside:', e);
 *   }
 * }
 * ```
 */
class NgClickOutsideDirective {
  constructor() {
    /**
     * Enables directive.
     */
    this.clickOutsideEnabled = true;
    /**
     * By default, the outside click event handler is automatically attached.
     *
     * Explicitely setting this to `true`sets the handler after the element is clicked. The outside click event handler
     * will then be removed after a click outside has occurred.
     */
    this.attachOutsideOnClick = false;
    /**
     * Delays the initialization of the click outside handler.
     * This may help for items that are conditionally shown ([see issue #13](https://github.com/arkon/ng-click-outside/issues/13)).
     */
    this.delayClickOutsideInit = false;
    /**
     * A comma-separated list of events to cause the trigger.
     * ### For example, for additional mobile support:
     * `[clickOutsideEvents]="'click,touchstart'"`
     */
    this.clickOutsideEvents = '';
    /**
     * Outside Click Event
     */
    this.clickOutside = new EventEmitter();
    this.excludeDirective = inject(NgClickOutsideExcludeToken, {
      host: true,
      optional: true
    });
    this._el = inject(ElementRef);
    this._ngZone = inject(NgZone);
    this.document = inject(DOCUMENT);
    this._events = ['click'];
    this._initOnClickBody = this._initOnClickBody.bind(this);
    this._onClickBody = this._onClickBody.bind(this);
    afterNextRender(() => this._init());
  }
  ngOnDestroy() {
    this._removeClickOutsideListener();
    this._removeAttachOutsideOnClickListener();
  }
  ngOnChanges(changes) {
    if (changes['attachOutsideOnClick'] || changes['emitOnBlur']) {
      this._init();
    }
  }
  _init() {
    if (this.clickOutsideEvents !== '') {
      this._events = this.clickOutsideEvents.split(',').map(e => e.trim());
    }
    if (this.attachOutsideOnClick) {
      this._initAttachOutsideOnClickListener();
    } else {
      this._initOnClickBody();
    }
  }
  _initOnClickBody() {
    if (this.delayClickOutsideInit) {
      setTimeout(this._initClickOutsideListener.bind(this));
    } else {
      this._initClickOutsideListener();
    }
  }
  _onClickBody(ev) {
    if (!this.clickOutsideEnabled) {
      return;
    }
    if (!this._el.nativeElement.contains(ev.target) && !this.excludeDirective?.isExclude(ev.target)) {
      this._emit(ev);
      if (this.attachOutsideOnClick) {
        this._removeClickOutsideListener();
      }
    }
  }
  _emit(ev) {
    if (!this.clickOutsideEnabled) {
      return;
    }
    this._ngZone.run(() => this.clickOutside.emit(ev));
  }
  _initClickOutsideListener() {
    this._ngZone.runOutsideAngular(() => {
      this._events.forEach(e => this.document.addEventListener(e, this._onClickBody));
    });
  }
  _removeClickOutsideListener() {
    this._ngZone.runOutsideAngular(() => {
      this._events.forEach(e => this.document.removeEventListener(e, this._onClickBody));
    });
  }
  _initAttachOutsideOnClickListener() {
    this._ngZone.runOutsideAngular(() => {
      this._events.forEach(e => this._el.nativeElement.addEventListener(e, this._initOnClickBody));
    });
  }
  _removeAttachOutsideOnClickListener() {
    this._ngZone.runOutsideAngular(() => {
      this._events.forEach(e => this._el.nativeElement.removeEventListener(e, this._initOnClickBody));
    });
  }
  static {
    this.ɵfac = function NgClickOutsideDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NgClickOutsideDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: NgClickOutsideDirective,
      selectors: [["", "clickOutside", ""]],
      inputs: {
        clickOutsideEnabled: "clickOutsideEnabled",
        attachOutsideOnClick: "attachOutsideOnClick",
        delayClickOutsideInit: "delayClickOutsideInit",
        clickOutsideEvents: "clickOutsideEvents"
      },
      outputs: {
        clickOutside: "clickOutside"
      },
      standalone: true,
      features: [i0.ɵɵNgOnChangesFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgClickOutsideDirective, [{
    type: Directive,
    args: [{
      selector: '[clickOutside]',
      standalone: true
    }]
  }], () => [], {
    clickOutsideEnabled: [{
      type: Input
    }],
    attachOutsideOnClick: [{
      type: Input
    }],
    delayClickOutsideInit: [{
      type: Input
    }],
    clickOutsideEvents: [{
      type: Input
    }],
    clickOutside: [{
      type: Output
    }]
  });
})();

/*
 * Public API Surface of ng-click-outside2
 */

/**
 * Generated bundle index. Do not edit.
 */

export { NgClickOutsideDirective, NgClickOutsideExcludeDirective, NgClickOutsideExcludeToken };
