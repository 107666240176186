<div class="grid-x align-center text-center">
  <div class="padding-m margin-bl cell">
    <zlo-icon name="zelo-logo" width="100px"></zlo-icon>
  </div>

  <div class="cell grass"></div>

  <div class="cell">
    <form [formGroup]="form" #ngForm="ngForm" (ngSubmit)="submit()">
      <div class="login-form-container grid-x">
        <div class="cell">
          <h4 class="login-title">
            {{ 'auth.forgotPassword.header' | translate }}
          </h4>
        </div>
        <div class="cell text-left">
          <label>
            {{ 'auth.emailLabel' | translate }}
            <span
              class="error-list"
              *ngIf="ngForm.submitted && form.get('email').hasError('required')"
            >
              {{ 'auth.errors.isRequired' | translate }}</span
            >
            <span
              class="error-list"
              *ngIf="ngForm.submitted && form.get('email').hasError('email')"
            >
              {{ 'auth.errors.isInvalid' | translate }}</span
            >
            <input
              type="text"
              [placeholder]="'auth.emailPlaceholder' | translate"
              formControlName="email"
              autocomplete="email"
              autofocus="true"
            />
          </label>
        </div>
        <div class="cell">
          <button
            class="button small expanded login-button"
            type="submit"
            mat-button
          >
            {{ 'auth.forgotPassword.button' | translate }}
          </button>
        </div>
      </div>
    </form>
  </div>
  <div class="cell login-nav-link">
    <div class="grid-y">
      <div class="cell shrink">
        <a routerLink="/auth/login" [queryParams]="{ email: form.value.email }">
          {{ 'auth.links.loginPassword' | translate }}
        </a>
      </div>
      <div class="cell shrink">
        <a
          routerLink="/auth/endusers"
          [queryParams]="{ email: form.value.email }"
        >
          {{ 'auth.links.sendOverview' | translate }}
        </a>
      </div>
    </div>
  </div>
</div>
