import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, map } from 'rxjs';
import * as fromAuth from '../reducers';

@Injectable({ providedIn: 'root' })
export class AuthRedirectGuard {
  constructor(private store: Store<fromAuth.State>, private router: Router) {}

  canActivate(
    _?: ActivatedRouteSnapshot,
    __?: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.store.select(fromAuth.getLoggedIn).pipe(
      map((loggedIn) => {
        if (loggedIn || localStorage.getItem('id_token')) {
          this.router.navigate(['/zelo']);
          return false;
        }
        return true;
      })
    );
  }
}
