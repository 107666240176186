import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import * as fromAuth from '../../auth/reducers';
import { Init } from '../../auth/actions/auth.actions';

@Component({
  selector: 'zlo-root',
  template: ` <router-outlet></router-outlet> `
})
export class AppComponent implements OnInit {
  constructor(
    translate: TranslateService,
    private store: Store<fromAuth.State>
  ) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.addLangs(['spama', 'no', 'en', 'fi', 'se', 'dk']);
    translate.setDefaultLang('en');
  }

  ngOnInit(): void {
    this.store.dispatch(Init());
  }
}
