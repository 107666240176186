import { NgModule, Type, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi
} from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { ToastrModule } from 'ngx-toastr';
import { JwtModule } from '@auth0/angular-jwt';

import { reducers, metaReducers } from '../core/reducers';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { AppComponent } from './containers/app.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { throwIfAlreadyLoaded } from './module-import-guard';
import { RouterStateSerializer } from '@ngrx/router-store';
import { CustomRouterStateSerializer } from './reducers';
import { environment } from '../../environments/environment';
import { RouterEffects } from './effects/router.effects';
import { MatPaginatorIntl } from '@angular/material/paginator';
import {
  TranslateModule,
  TranslateLoader,
  TranslateService
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppRoutingModule } from '../app-routing.module';
import { PaginatorI18n } from './../shared/services/paginatorI18n.service';

export function tokenGetter() {
  return localStorage.getItem('id_token');
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export const COMPONENTS: Type<any>[] = [AppComponent, NotFoundComponent];

@NgModule({
  declarations: COMPONENTS,
  exports: COMPONENTS,
  imports: [
    CommonModule,
    RouterModule,
    AppRoutingModule,
    JwtModule.forRoot({
      config: {
        tokenGetter,
        allowedDomains: environment.production
          ? [
              'api.zelo.no',
              'api.zelo.app',
              'api.staging.zelo.no',
              'api.staging.zelo.app',
              'api.develop.zelo.no',
              'api.develop.zelo.app'
            ]
          : ['localhost:8080', 'api.develop.zelo.no'],
        disallowedRoutes: [],
        skipWhenExpired: true
      }
    }),
    ToastrModule.forRoot({
      positionClass: 'toast-top-center',
      toastClass: 'zelo-toast',
      timeOut: 3000
    }),
    /**
     * StoreModule.forRoot is imported once in the root module, accepting a reducer
     * function or object map of reducer functions. If passed an object of
     * reducers, combineReducers will be run creating your application
     * meta-reducer. This returns all providers for an @ngrx/store
     * based application.
     */
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false
      }
    }),
    /**
     * @ngrx/router-store keeps router state up-to-date in the store.
     */
    StoreRouterConnectingModule.forRoot({
      /*
            They stateKey defines the name of the state used by the router-store reducer.
            This matches the key defined in the map of reducers
           */
      stateKey: 'router'
    }),
    /**
     * Store devtools instrument the store retaining past versions of state
     * and recalculating new states. This enables powerful time-travel
     * debugging.
     *
     * To use the debugger, install the Redux Devtools extension for either
     * Chrome or Firefox
     *
     * See: https://github.com/zalmoxisus/redux-devtools-extension
     */
    StoreDevtoolsModule.instrument({
      name: 'NgRx Zelo Store DevTools',
      logOnly: environment.production,
      connectInZone: true
    }),
    /**
     * EffectsModule.forRoot() is imported once in the root module and
     * sets up the effects class to be initialized immediately when the
     * application starts.
     *
     * See: https://github.com/ngrx/platform/blob/master/docs/effects/api.md#forroot
     */
    EffectsModule.forRoot([RouterEffects]),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    {
      provide: RouterStateSerializer,
      useClass: CustomRouterStateSerializer
    },
    {
      provide: MatPaginatorIntl,
      useClass: PaginatorI18n,
      deps: [TranslateService]
    },
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule
  ) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  } /*

      ]
    };
  } */

  /* static forRoot(): ModuleWithProviders {
    return {
      ngModule: CoreModule,
      providers: [
        /**
         * The `RouterStateSnapshot` provided by the `Router` is a large complex structure.
         * A custom RouterStateSerializer is used to parse the `RouterStateSnapshot` provided
         * by `@ngrx/router-store` to include only the desired pieces of the snapshot.
         */
}
