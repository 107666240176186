import {
  SlackCredentials,
  TeamsCredentials,
  WorkplaceCredentials,
  Whitelabel
} from './settings.types';

import { Department } from './organization.types';
import {
  RecipientChannel,
  EndUserSettings,
  AttributeTerm,
  Employee
} from './zelo';

export interface Authenticate {
  email: string;
  password?: string;
  otp?: string;
  sso?: boolean;
}

export interface AuthenticateRepsonse {
  dbHostname?: string;
  token: string;
  user: User;
  redirectUrl?: string;
  status?: string;
}

export interface Registration {
  email?: string;
  password?: string;
  firstName?: string;
  lastName?: string;
  orgName?: string;
  sessionId?: string;
  isAutomatedTest?: boolean;
  tier?: 'STARTER' | 'STANDARD' | 'PREMIUM' | 'E2E';
}

export interface PasswordChange {
  oldPassword: string;
  newPassword: string;
}

export interface ZelosPermissions {
  canEdit: boolean;
  canDuplicate: boolean;
  canSend: boolean;
}

export interface SettingsPermissions {
  senders: {
    canAdd: boolean;
    canEdit: boolean;
    canDelete: boolean;
  };
}

export interface Channel {
  id: string;
  channelName: string;
  isActive: boolean;
  createdAt?: string;
  updatedAt?: string;
  credentials?: SlackCredentials | TeamsCredentials | WorkplaceCredentials | {};
}

export interface OtpResult {
  channel: string;
  status: string;
}

export enum ChannelMessageNames {
  email = 'email',
  slack = 'slack',
  sms = 'sms',
  teams = 'teams',
  whatsapp = 'whatsapp',
  workplace = 'workplace'
}

export interface OrganizationSettings {
  preferredChannelId: string;
  channel: string;
  organizationId: string;
  requirePhoneConsent: boolean;
  whitelabel?: Whitelabel;
  preferredTimezone: string;
  enableEmailTrackingPixel: boolean;
  enableLinkTracking: boolean;
}

export interface OrganizationAuthSettings {
  emailOtp: boolean;
  enduserAuth: boolean;
  defaultUserLoginMethods: string[];
  availableLoginMethods: string[];
}

export interface UserOrganization {
  id: string;
  name: string;
  language: string;
  organizationNumber: string;
  verified: boolean;
  tier: 'FREE' | 'STARTER' | 'STANDARD' | 'PREMIUM' | 'ENTERPRISE' | 'E2E';
  limit: number | string;
  userLimit: number;
  userCount: number;
  employeeCount: number;
  channels: Channels;
  settings?: Partial<OrganizationSettings>;
  senders: {
    [key: string]: Sender;
  };
  logo: string;
  attrs: OrgAttrs;
  autocomplete: Partial<Employee>[];
  e2e: boolean;
  departments: Department[];
  whitelabel: Whitelabel;
  authSettings: OrganizationAuthSettings;
}

export interface Sender {
  id?: string;
  sender: {
    name: string;
    domain: string;
    localPart: string; // before the @ in an email
  };
  replyTo: {
    name: string;
    domain: string;
    localPart: string; // before the @ in an email
  };
  filterDepartmentIds?: boolean;
  departmentIds?: string[];
}

export interface OrgAttr {
  attr: string;
  name?: string;
  orgid: string;
  meta?: Object;
  i18n?: Partial<Record<string, string>>;
  terms?: Partial<Record<string, Partial<Record<string, string>>>>;
  attributeTerms?: AttributeTerm[];
}

export interface UserAttr {
  attr: string;
  userid?: string;
  orgid?: string;
  terms?: Partial<Record<string, any>>;
}

export type OrgAttrs = Array<OrgAttr>;
export type UserAttrs = Array<UserAttr>;
export type Channels = Partial<Record<RecipientChannel, Channel>>;

export interface User {
  id: string;
  firstname: string;
  lastname: string;
  email: string;
  phone?: string;
  organization: UserOrganization;
  roles: {
    isAdmin?: boolean;
    isManager?: boolean;
    isWriteManager: boolean;
  };
  permissions: {
    zelos: ZelosPermissions;
    settings: SettingsPermissions;
  };
  departments: Department[];
  loginDepartment?: string;
  settings?: EndUserSettings;
  prefs?: any;
  privacyVersion?: string;
  privacyLink?: string;
  createdAt: string;
}
